import React, { useMemo } from 'react'
import { withTheme } from 'styled-components'

import { stringNotEmpty, ensureArray, ensureObject } from '@agnostack/lib-core'

import TemplatedPage from '../components/molecules/TemplatedPage'
import {
  SEO,
  StickyAccordion,
  TreeMenu,
  NavigationButtons,
  SubscribeButton,
} from '../components/atoms'
import { withBreakpoint } from '../components/hocs'
import { getTitle } from '../gatsby'

const CTA = () => (
  <SubscribeButton>
    Subscribe To Updates
  </SubscribeButton>
)

export default withTheme(({ pageContext }) => {
  const ContentNav = withBreakpoint(StickyAccordion, TreeMenu)
  const {
    node: {
      frontmatter: {
        category,
        version: releaseVersion,
        format: pageFormat,
        canonicalPath,
        parentPath,
        path,
        imagePath,
        keywords,
        description,
        siteMetadata: {
          tagline,
        } = {},
      } = {},
    } = {},
    paths = [],
  } = ensureObject(pageContext)

  const articleKeywords = `release notes${releaseVersion ? `, ${releaseVersion}` : ''}${keywords ? `, ${keywords}` : ''}`

  const { title, headline } = useMemo(() => ({
    title: `${getTitle({ context: pageContext })}: ${tagline}`,
    headline: category || 'Release Number',
  }), [pageContext, category, tagline])

  // NOTE: passing labels as ReleaseNotes are reverse order
  const navigation = (paths[category].length <= 1
    ? null
    : (
      <NavigationButtons
        path={(stringNotEmpty(parentPath) && stringNotEmpty(path)) ? path : ensureObject(ensureArray(paths[category])[0])}
        subPaths={paths[category]}
        labels={['Next', 'Previous']}
      />
    )
  )

  return (
    <>
      <SEO
        type="article"
        title={title}
        path={path}
        imagePath={imagePath}
        canonicalPath={canonicalPath}
        keywords={articleKeywords}
        description={description}
      />
      <TemplatedPage
        data={pageContext}
        headline={headline}
        CTA={CTA}
        tagline={releaseVersion}
        format={pageFormat || 'accent2'}
        navigation={navigation}
        parentPath={parentPath}
        path={path}
      >
        <ContentNav
          path={path}
          canonicalPath={canonicalPath}
          parentPath={parentPath}
          paths={paths}
          category={category}
        />
      </TemplatedPage>
    </>
  )
})
